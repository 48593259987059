import userAPI from '../../api/user'
import * as types from '../types/user'
import * as commonTypes from '../types/common'

export const getDefaultState = () => ({
  loaded: false,
  loading: false,
  developerAccess: false,
  userName: null,
  metaData: null,
  scrapingRegions: [
    {id: 'au', name: 'Australia', country: 'AU', scrapingRegion: 'au'},
    {id: 'nz', name: 'New Zealand', country: 'NZ', scrapingRegion: 'nz'},
    {id: 'us', name: 'United States', country: 'US', scrapingRegion: 'us'},
    {id: 'it', name: 'Italy', country: 'IT', scrapingRegion: 'it'},
    // {id: 'nl', name: 'The Netherlands', country: 'NL', scrapingRegion: 'nl'}
  ]
})

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  async [types.ACTION_LOAD_PREFERENCES] ({ commit }) {
    commit(types.MUTATION_SET_LOADED, false)
    commit(types.MUTATION_SET_LOADING, true)
    // userAPI.getPreferences(preferences => {
    return userAPI.getPreferences().then(response => {
      if (response && response.preferences) {
        const hasDeveloperAccess = response.preferences.developerAccess || false
        const userName = response.preferences.userName || null

        const isSysAdmin = response.preferences.isSysAdmin || false

        commit(types.MUTATION_SET_DEVELOPER_ACCESS, hasDeveloperAccess)
        commit(types.MUTATION_SET_LOADED, true)
        if (response.metaData) {
          commit(types.MUTATION_SET_META_DATA, {...response.metaData, isSysAdmin: isSysAdmin})
        }
        if (userName) {
          commit(types.MUTATION_SET_USER_NAME, userName)
        }
      }
      commit(types.MUTATION_SET_LOADING, false)
      return response.preferences
    })
  }
}

// mutations
const mutations = {
  [commonTypes.MUTATION_RESET_STATE] (state) {
    // console.log('RESETING STATE in Decisions Module')
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_SET_DEVELOPER_ACCESS] (state, hasDeveloperAccess) {
    state.developerAccess = hasDeveloperAccess
  },
  [types.MUTATION_SET_LOADED] (state, newVal) {
    state.loaded = newVal
  },
  [types.MUTATION_SET_LOADING] (state, newVal) {
    state.loading = newVal
  },
  [types.MUTATION_SET_META_DATA] (state, newVal) {
    state.metaData = newVal
  },
  [types.MUTATION_SET_USER_NAME] (state, newVal) {
    state.userName = newVal
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
